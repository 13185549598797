import {OrderModel} from '../../models/OrderModel';
import api from './axios';
import Config from '../util/Config';
import Cookies from 'js-cookie';
import {AxiosError} from "axios";

const fetchOrders = async (): Promise<OrderModel[]> => {
    try {
        let response = await api.get(Config.api.orders, {headers: {Authorization: `Bearer ${Cookies.get('access')}`}});
        return response.data.orders.map((order: any) => OrderModel.fromApi(order));    } catch (e) {
        Cookies.remove('access');
        throw (e as AxiosError).status;
    }
}

export default fetchOrders;
