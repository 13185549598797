import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import Config from "../util/Config";

export interface ProductOrder {
    id: string;
    amount: number;
}

export interface ProductOrderPayload {
    products: ProductOrder[];
}

const createOrder = async (locationId: string, data: ProductOrderPayload) => {
    try {
        await axios.post(`${Config.api.createOrder}/${locationId}`, data, {
        headers: {
            'Authorization': `Bearer ${Cookies.get('access')}`
        }
    });
} catch (e) {
    let response = (e as AxiosError).response;
    throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
}
};

export default createOrder;