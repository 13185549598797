// OrderedProductModel.ts
export interface OrderedProductInterface {
    product_id: string;
    amount: number;
    price: number;
    product_name: string;
}

export class OrderedProductModel {
    product_id: string;
    amount: number;
    price: number;
    product_name: string;

    constructor(data: OrderedProductInterface) {
        this.product_id = data.product_id;
        this.amount = data.amount;
        this.price = data.price;
        this.product_name = data.product_name;
    }

    static fromApi(data: any): OrderedProductModel {
        return new OrderedProductModel({
            product_id: data.product_id,
            amount: data.amount,
            price: data.price,
            product_name: data.product_name,
        });
    }
}