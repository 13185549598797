import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";
import {FetchFilterModel} from "../../models/FetchFilterModel";

const fetchFilters = async () => {
    return new Promise<FetchFilterModel[]>(async (resolve, reject) => {
        try {
            const response = await api.get(Config.api.fetchFilters);
            resolve(response.data.Filters.map((el: any) => FetchFilterModel.fromApi(el)));
        } catch(e) {
            reject((e as AxiosError).status)
            return
        }
    });
};

export default fetchFilters;