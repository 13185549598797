import Slider from 'react-slider';
import React, {useEffect} from 'react';
import {useGlobalContext} from "../util/GlobalProvider";
import {FiltersInterface} from "../../models/FiltersModel";
import {SubCategoryModel} from "../../models/SubCategoryModel";
import {BrandModel} from "../../models/BrandModel";
import config from "../util/Config";
interface Filter {
    key: string;
    value: string;
}
const parseFilters = (filters: string[]): Filter[] => {
    return filters.map((filter) => {
        const [key, value] = filter.split(':');
        return { key, value };
    });
};
const Filters: React.FC<FiltersInterface> = ({...filters}) => {
    const {
        subCategories = [],
        brands = [],
        showRetailCost,
        setShowRetailCost,
        showWholeSaleCost,
        setShowWholeSaleCost,
        apiFilters= [],
    } = useGlobalContext();
    useEffect(() => {
        const storedShowRetailCost = localStorage.getItem('showRetailCost');
        const storedShowWholeSaleCost = localStorage.getItem('showWholeSaleCost');
        if (storedShowRetailCost !== null) {
            setShowRetailCost(storedShowRetailCost === 'true');
        }
        if (storedShowWholeSaleCost !== null) {
            setShowWholeSaleCost(storedShowWholeSaleCost === 'true');
        }
    }, [setShowRetailCost, setShowWholeSaleCost]);
    useEffect(() => {
        localStorage.setItem('showRetailCost', String(showRetailCost));
    }, [showRetailCost]);

    useEffect(() => {
        localStorage.setItem('showWholeSaleCost', String(showWholeSaleCost));
    }, [showWholeSaleCost]);

    const handleChange = (newValue: number[]) => {
        filters.setCostRange(newValue);
        filters.setMinCost(newValue[0]);
        filters.setMaxCost(newValue[1]);
    };

    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMin = parseInt(e.target.value)!;
        if (newMin >= 0 && newMin <= filters.costRange[1]) {
            filters.setMinCost(newMin);
            filters.setCostRange([newMin, filters.costRange[1]]);
        }
    };

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMax = parseInt(e.target.value);
        if (!isNaN(newMax) && newMax >= filters.costRange[0] && newMax <= 100000) {
            filters.setMaxCost(newMax);
            filters.setCostRange([filters.costRange[0], newMax]);
        }
    };

    const toggleInStock = () => {
        filters.setInStock(!filters.inStock);
    };


    const handleBrandChange = (brand: BrandModel) => {
        const newBrands = filters.selectedBrands.includes(brand)
            ? filters.selectedBrands.filter(item => item.id !== brand.id)
            : [...filters.selectedBrands, brand];
        filters.setSelectedBrands(newBrands);
    };

    const filteredSubCategories = subCategories.filter(subCategory => subCategory.categoryId === filters.category?.id);

    const handleSubCategoryChange = (subCategory: SubCategoryModel) => {
        const newSubCategories = filters.selectedSubCategories.includes(subCategory)
            ? filters.selectedSubCategories.filter(item => item.id !== subCategory.id)
            : [...filters.selectedSubCategories, subCategory];
        filters.setSelectedSubCategories(newSubCategories);
    };

    const categoryId = filters.category?.id as keyof typeof config.filters;
    const categoryFilters = categoryId
        ? apiFilters.filter(filter => filter.category_id === categoryId)
        : null;

    const handleCheckboxChange = (filterName: string, value: string) => {
        let updatedFilters = [...(filters.filterArray ?? [])];
        const filterExists = updatedFilters.find(
            (f) => f.startsWith(`${filterName}:${value}`)
        );
        if (filterExists) {
            updatedFilters = updatedFilters.filter(
                (f) => !f.startsWith(`${filterName}:${value}`)
            );
        } else {
            updatedFilters.push(`${filterName}:${value}`);
        }
        filters.setFilterArray(updatedFilters);
    };

    const queryFilters = parseFilters(filters.filterArray ?? []);

    return (
        <div className="filters">
            <h4 className="title-catalog">{filters.category?.name}</h4>
            <div className="filter">
                <h5>Наличие</h5>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="inStock"
                        checked={filters.inStock}
                        onChange={toggleInStock}
                    />
                    <label className="form-check-label" htmlFor="inStock">В наличии</label>
                </div>
            </div>

            <div className="filter">
                <h5>Цена</h5>
                <div>
                    <div className="price-range">
                        <input
                            type="number"
                            name="min"
                            className="form-control price-range-input"
                            value={filters.minCost}
                            onChange={handleMinChange}
                        />
                        <input
                            type="number"
                            name="max"
                            className="form-control price-range-input"
                            value={filters.maxCost}
                            onChange={handleMaxChange}
                        />
                    </div>
                    <Slider
                        className="slider"
                        valueLabelDisplay="auto"
                        value={filters.costRange}
                        onChange={handleChange}
                        min={0}
                        max={100000}
                    />
                    {/* <p>Price Range: ${sliderValue[0]} - ${sliderValue[1]}</p> */}
                </div>
            </div>

            {filteredSubCategories.length === 0 ? '' :
                <div className="filter">
                    <h5>Подкатегории</h5>
                    <div className="subcategory-container">
                        <div className="subcategory-container-scrollable">
                            {filteredSubCategories.map(subCategory => (
                                <div key={subCategory.id} className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`subcategory-${subCategory.id}`}
                                        checked={filters.selectedSubCategories.map(sc => sc.id).includes(subCategory.id)}
                                        onChange={() => handleSubCategoryChange(subCategory)}
                                    />
                                    <label className="form-check-label" htmlFor={`subcategory-${subCategory.id}`}>
                                        {subCategory.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }

            <div className="filter">
                <h5>Бренды</h5>
                <div className="subcategory-container">
                    <div className="subcategory-container-scrollable">
                        {brands.map(brand => (
                            <div key={brand.id} className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`brand-${brand.id}`}
                                    checked={filters.selectedBrands.map(sb => sb.id).includes(brand.id)}
                                    onChange={() => handleBrandChange(brand)}
                                />
                                <label className="form-check-label" htmlFor={`brand-${brand.id}`}>
                                    {brand.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="filter">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="retailCostSwitch"
                           onChange={() => setShowRetailCost(!showRetailCost)} checked={showRetailCost}/>
                    <label className="form-check-label" htmlFor="retailCostSwitch">Розничная цена</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="wholeSaleCostSwitch"
                           onChange={() => setShowWholeSaleCost(!showWholeSaleCost)}
                           checked={showWholeSaleCost}/>
                    <label className="form-check-label" htmlFor="wholeSaleCostSwitch">Оптовая цена</label>
                </div>
            </div>
            <div>
                {categoryFilters &&
                    categoryFilters.map((filter) => (
                        <div key={filter.id} className="filter">
                            <h5>{filter.name}</h5>
                            <div className="subcategory-container" >
                                <div className="subcategory-container-scrollable">
                                    {filter.values.map((value, valueIndex) => (
                                        <div key={valueIndex} className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`filter-${filter.id}-value-${valueIndex}`}
                                                checked={!!queryFilters.find((f) => f.key === filter.name && f.value === value)}
                                                onChange={() => handleCheckboxChange(filter.name, value)}
                                            />
                                            <label className="form-check-label"
                                                   htmlFor={`filter-${filter.id}-value-${valueIndex}`}>
                                                {value}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Filters;