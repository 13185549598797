import Config from "../util/Config";
import Cookies from "js-cookie";
import api from "./axios";
import {AxiosError} from "axios";


const deleteLocation = async (locationId: string) => {
    try {
        await api.post(`${Config.api.deleteLocation}/${locationId}`, {}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access')}`
            }
        });
    } catch (e) {
        let response = (e as AxiosError).response
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default deleteLocation;
