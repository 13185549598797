import { OrderedProductModel, OrderedProductInterface } from './OrderedProductModel';

export interface OrderInterface {
    id: string;
    user_location_id: string;
    ordered_products: OrderedProductInterface[];
    created: string;
    status: string;
    c1Id: string;
    confirmed: boolean;
}

export class OrderModel {
    id: string;
    user_location_id: string;
    ordered_products: OrderedProductModel[];
    created: string;
    status: string;
    c1Id: string;
    confirmed: boolean;


    constructor(data: OrderInterface) {
        this.id = data.id;
        this.user_location_id = data.user_location_id;
        this.ordered_products = data.ordered_products.map(product => new OrderedProductModel(product));
        this.created = data.created;
        this.status = data.status;
        this.c1Id = data.c1Id;
        this.confirmed = data.confirmed;
    }

    static fromApi(data: any): OrderModel {
        return new OrderModel({
            id: data.id,
            user_location_id: data.user_location_id,
            ordered_products: data.ordered_products.map((product: any) => OrderedProductModel.fromApi(product)),
            created: data.created,
            status: data.status,
            c1Id: data.c1_id,
            confirmed: data.confirmed,
        });
    }
}