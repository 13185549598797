import React, { useState } from 'react';
import Modal from 'react-modal';
import {BusinessLocationModel} from "../../models/BusinessLocationModel";

interface AddressModalViewProps {
    isOpen: boolean;
    setShowModal: (show: boolean) => void;
    addresses:  BusinessLocationModel[];
    onAddressSelected: (addressId: string) => void;
}

const AddressModalView: React.FC<AddressModalViewProps> = ({
                                                               isOpen,
                                                               setShowModal,
                                                               addresses,
                                                               onAddressSelected
                                                           }) => {
    const [selectedAddress, setSelectedAddress] = useState<string>('');

    const handleSelect = () => {
        onAddressSelected(selectedAddress);
        setShowModal(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Выбор адреса"
            overlayClassName="modal-overlay"
            className="modal-content"
            closeTimeoutMS={400}
        >
            <div className="common-div-form">
                <button onClick={() => setShowModal(false)} className="close-modal-btn">×</button>
                <h5 style={{marginBottom: "10px"}}>Выбор адреса</h5>
                <select className="form-select" value={selectedAddress}
                        onChange={e => setSelectedAddress(e.target.value)}>
                    <option value="" disabled>Выберите адрес</option>
                    {addresses.length > 0 && addresses.map((address) => (
                        <option key={address.id} value={address.id}>{address.address}</option>
                    ))}
                </select>
                <div style={{width: 'inherit'}}>
                    <button
                        onClick={handleSelect}
                        className="common-btn action-modal"
                    >
                        Оформить заказ
                    </button>
                    <button
                        onClick={() => setShowModal(false)}
                        className="common-btn action-modal cancel-btn"
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddressModalView;