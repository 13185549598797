import Config from "../util/Config";
import Cookies from "js-cookie";
import api from "./axios";
import {AxiosError} from "axios";


const confirmOrder = async (orderId: string) => {
    try {
        await api.post(`${Config.api.confirmOrder}/${orderId}`, {}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access')}`
            }
        });
    } catch (e) {
        let response = (e as AxiosError).response
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default confirmOrder;