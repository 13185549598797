import React, { useEffect, useState } from "react";
import onStartPageLoaded from "../util/onStartPageLoaded";
import fetchOrders from "../api/fetchOrders";
import {NotificationManager} from "react-notifications";
import Loader from "./Loader";
import Config from "../util/Config";
import ActionConfirmModalView from "./ActionConfirmModalView";
import deleteOrder from "../api/deleteOrder";
import {BusinessLocationModel} from "../../models/BusinessLocationModel";
import confirmOrder from "../api/confirmOrder";

interface AddressesProps {
    locations: BusinessLocationModel[];
}

const Orders: React.FC<AddressesProps>  = ({locations}) => {
    const [orders, setOrders] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
    const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);

    const getOrders = async () => {
        try {
            const fetchedOrders = await fetchOrders();
            setOrders(fetchedOrders);
        } catch (error) {
            NotificationManager.error('Произошла ошибка при загрузке заказов!', 'Ошибка');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onStartPageLoaded();
        getOrders();
    }, []);

    const handleOrderDeletion = async (orderId: string | null) => {
        if (!orderId) {
            return
        }
        try {
            await deleteOrder(orderId);
            NotificationManager.success('Заказ Удален!', 'Успех');
            getOrders();
        } catch {
            NotificationManager.error('Ошибка при удалении заказа!', 'Ошибка');
        }
    }
    const handleOrderConfirm = async (orderId: string | null) => {
        if (!orderId) {
            return
        }
        try {
            await confirmOrder(orderId);
            NotificationManager.success('Заказ Подтвержден!', 'Успех');
            getOrders();
        } catch {
            NotificationManager.error('Ошибка при подтверждении заказа!', 'Ошибка');
        }
    }


    return (
        <div className="orders">
            <div className="orders-div">
                <span className="orders-component-title">Заказы</span>
                {loading ? (
                    <div className="no-orders-div"><Loader/></div>
                ) : orders && orders.length > 0 ? (
                    orders.map((order: any) => {
                        const total = order.ordered_products.reduce((total: number, product: { amount: number; price: number; }) => {
                            return total + product.amount * product.price;
                        }, 0);
                        const location = locations.find(loc => loc.id === order.user_location_id);
                        return (
                            <div key={order.id} className="order">
                                <div className="order-info">
                                    <span className="order-id">Заказ: {order.c1Id}</span>
                                    {order.status === "В резерве" && !order.confirmed ?
                                        <span className="confirm-order" onClick={() => {
                                            setSelectedOrder(order.id);
                                            setShowConfirmOrderModal(true)
                                        }}>Подтвердите заказ</span> :
                                        <span className="order-status">{order.status}</span>
                                    }
                                    <ActionConfirmModalView
                                        isOpen={showConfirmOrderModal}
                                        setShowModal={setShowConfirmOrderModal}
                                        onConfirm={() => handleOrderConfirm(selectedOrder)}
                                        confirmText="Подтвердить"
                                        cancelText="Отмена"
                                        message="Вы точно хотите подтвердить заказ?"
                                    />
                                </div>
                                <span className="order-address">Адрес: {location ? location.address : 'Адрес удален'}</span>
                                {order.ordered_products.map((product: any) => (
                                    <div key={product.product_id} className="order-product">
                                        <a href={`${Config.urls.product}/${product.product_id}`} target="_blank" rel="noreferrer" className="order-product-id">&#8226; {product.product_name}</a>
                                        <span className="order-product-price">{product.amount} шт &times; {product.price.toLocaleString('ru-RU', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}₽</span>
                                    </div>
                                ))}
                                <div className="order-total">
                                    <span className="order-total-text">Итого: {total.toLocaleString('ru-RU', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}₽</span>
                                    <button onClick={() => {
                                        setSelectedOrder(order.id);
                                        setShowDeleteModal(true)
                                    }} className="order-delete">Удалить
                                    </button>
                                    <ActionConfirmModalView
                                        isOpen={showDeleteModal}
                                        setShowModal={setShowDeleteModal}
                                        onConfirm={() => handleOrderDeletion(selectedOrder)}
                                        confirmText="Удалить"
                                        cancelText="Отмена"
                                        message="Вы точно хотите удалить заказ?"
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="no-orders-div">
                        У вас пока нет заказов<br/>
                        Добавьте товары в корзину и оформите заказ
                    </div>
                )}
            </div>
        </div>
    );
};

export default Orders;