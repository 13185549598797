export interface FetchFilterInterface {
    id: string;
    category_id: string;
    name: string;
    type: string;
    values : string[];
}

export class FetchFilterModel {
    id: string;
    category_id: string;
    name: string;
    type: string;
    values : string[];

    constructor(data: FetchFilterInterface) {
        this.id = data.id;
        this.category_id = data.category_id;
        this.name = data.name;
        this.type = data.type;
        this.values = data.values;
    }

    static fromApi(data: any): FetchFilterModel {
        return new FetchFilterModel({
            id: data.id,
            category_id: data.category_id,
            name: data.name,
            type: data.type,
            values: data.values
        });
    }
}